<template>
  <div>
    <actions-popup v-if="categoryPopup" @close-popup="togglePopup" isCategory="yes" :elementId="categoryId"/>

    <vs-row vs-w="12">
      <vs-col class="mb-8" vs-type="flex" vs-justify="space-between">
        <vs-button
          color="primary"
          type="gradient"
          icon-pack="feather"
          icon="icon-plus"
          @click="addNew"
        >{{$t('Create')}}
        </vs-button>

      </vs-col>

      <vs-col vs-w="12">
        <div id="data-list-list-view" class="data-list-container">

          <vs-pagination-table  model_type="transactionCategory" :data="transactionCategories"
          :table_name="$t('Transaction Categories')">
            <template slot-scope="{data}">
              <template>

                <vs-th sort-key="name">{{$t('Name')}}</vs-th>
                <vs-th>{{$t('Sub Category Count')}}</vs-th>

                <vs-th>{{$t('Options')}}</vs-th>
              </template>
              <template>
                <vs-tr :key="index" v-for="(tr, index) in data">

                  <vs-td>
                    <router-link
                        class="ml-1 mr-1"
                        :to="{
                        name: 'transaction_categories_sub_categories',
                        params: { id: tr.id }
                      }"
                      >
                    {{ tr.name }}
                    </router-link>
                  </vs-td>
                  <vs-td>
                    {{ tr.parents_count }}
                  </vs-td>
                  <!-- <vs-td>
                    <div style="width: max-content">
                      <vs-chip transparent color="success">
                        <vs-avatar
                          color="rgb(var(--vs-success))"
                          icon="event_available"
                        />
                         <vx-tooltip color="success" :text="tr.created_from">
                          {{ tr.created_at }}
                        </vx-tooltip>
                      </vs-chip>
                    </div>
                    <div style="width: max-content">
                      <vs-chip transparent color="warning">
                        <vs-avatar color="rgb(var(--vs-warning))" icon="update"/>
                      <vx-tooltip
                          transparent
                          color="warning"
                          :text="tr.updated_from">
                          {{ tr.updated_at }}
                        </vx-tooltip>
                      </vs-chip>
                    </div>
                  </vs-td> -->
                  <vs-td>
                    <div class="auto-cols-max">

                      <a
                        class="mx-1"
                        href="javascript:void(0)"
                        @click="edit(tr.id)"
                      >
                        <font-awesome-icon
                          color="rgb(var(--vs-success))"
                          :icon="['fas', 'edit']"
                        />
                      </a>
                      <a
                        class="mx-1"
                        href="javascript:void(0)"
                        @click="confirmDelete(tr.id)"
                      >
                        <font-awesome-icon
                          color="rgb(var(--vs-danger))"
                          :icon="['fas', 'trash-alt']"
                        />
                      </a>


                    </div>
                  </vs-td>
                </vs-tr>
              </template>
            </template>
          </vs-pagination-table>
        </div>
      </vs-col>
    </vs-row>
  </div>
</template>

<script>
/* eslint-disable */
import ActionsPopup from "@/views/pages/transactionCategory/actionsPopup";

export default {
  components: {
    ActionsPopup,
  },
  computed:{
    transactionCategories() {
      return this.$store.getters["transactionCategory/data"]
    },
  },
  data() {
    return {
      categoryPopup: false,
      categoryId: ''
    };
  },
  methods: {
    confirmDelete(element_id) {
      this.selectedElement = element_id;
      this.deleteElement(element_id)
    },
    deleteElement(element_id){
      this.$store.dispatch('transactionCategory/Delete', {'id':element_id})
    },
    addNew() {
      this.categoryId = ''
      this.togglePopup(true)
    },
    edit(id) {
      this.categoryId = id
      this.togglePopup(true)
    },
    togglePopup(val = false) {
      this.categoryPopup = val
    }
  },
  created() {
    this.$store.dispatch("transactionCategory/SetSectionName",{'sectionName':"transaction_categories"})
  }
};
</script>

<style lang="scss">
#data-list-list-view {
  .vs-con-table {
    /*
      Below media-queries is fix for responsiveness of action buttons
      Note: If you change action buttons or layout of this page, Please remove below style
    */
    @media (max-width: 689px) {
      .vs-table--search {
        margin-left: 0;
        max-width: unset;
        width: 100%;

        .vs-table--search-input {
          width: 100%;
        }
      }
    }

    @media (max-width: 461px) {
      .items-per-page-handler {
        display: none;
      }
    }

    @media (max-width: 341px) {
      .data-list-btn-container {
        width: 100%;

        .dd-actions,
        .btn-add-new {
          width: 100%;
          margin-right: 0 !important;
        }
      }
    }

    .product-name {
      max-width: 23rem;
    }

    .vs-table--header {
      display: flex;
      flex-wrap: wrap;
      margin-left: 1.5rem;
      margin-right: 1.5rem;

      > span {
        display: flex;
        flex-grow: 1;
      }

      .vs-table--search {
        padding-top: 0;

        .vs-table--search-input {
          padding: 0.9rem 2.5rem;
          font-size: 1rem;

          & + i {
            left: 1rem;
          }

          &:focus + i {
            left: 1rem;
          }
        }
      }
    }

    .vs-table {
      border-collapse: separate;
      border-spacing: 0 1.3rem;
      padding: 0 1rem;

      tr {
        box-shadow: 0 4px 20px 0 rgba(0, 0, 0, 0.05);

        td {
          padding: 20px;

          &:first-child {
            border-top-left-radius: 0.5rem;
            border-bottom-left-radius: 0.5rem;
          }

          &:last-child {
            border-top-right-radius: 0.5rem;
            border-bottom-right-radius: 0.5rem;
          }
        }

        td.td-check {
          padding: 20px !important;
        }
      }
    }

    .vs-table--thead {
      th {
        padding-top: 0;
        padding-bottom: 0;

        .vs-table-text {
          text-transform: uppercase;
          font-weight: 600;
        }
      }

      th.td-check {
        padding: 0 15px !important;
      }

      tr {
        background: none;
        box-shadow: none;
      }
    }

    .vs-table--pagination {
      justify-content: center;
    }
  }
}
</style>
